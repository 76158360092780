<template>
  <div class="image-setup">
    <!-- <img src="@/assets/img/mandaya.png" class="w-100"> -->
  </div>
  <overlay>
    <div class="setup-hidden" @click="hiddenSetupLayout">&nbsp;</div>
     <div class="w-100">
      <overlay-header :icon="icon" :header-text="$l.detectLanguage('infoSetup')" />
     </div>
      <overlay-info />
     <overlay-footer/>
  </overlay>
</template>

<script>
import Overlay from '@/components/Overlay/Overlay.vue'
import OverlayHeader from '@/components/Overlay/OverlayHeader'
import OverlayFooter from '@/components/Overlay/OverlayFooterProvider'
import OverlayInfo from '@/components/Overlay/OverlayInfoProvider.vue'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    // dataMachine: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  components: {
    Overlay,
    OverlayHeader,
    OverlayFooter,
    OverlayInfo
  },
  computed: {
    ...mapState({
      IPAddress: state => state.IP
    })
  },
  mounted () {
    // this.getConfigKios()
  },
  data () {
    return {
      // icon: require('@/assets/icon/setup.svg'),
      icon: require('@/assets/gif/setting-kiosk.gif'),
      isTimeOut: '',
      clickNow: 0
    }
  },
  methods: {
    ...mapActions({
      setConfigKios: 'SET_CONFIG_POLI',
      getConfigKios: 'GET_CONFIG_POLI'
    }),
    hiddenSetupLayout () {
      this.clickNow++
      clearTimeout(() => {
        this.clickNow = 0
      }, 2000)
      if (this.clickNow === 4) {
        this.setConfigKios()
      }
    }
  }
}
</script>

<style>

</style>
